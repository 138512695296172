<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  ></loading>
  <div class="acceptDepot" v-show="showInterfaceForAccptDepot">
    <form>
      <h5 class="text-center">Voulez-vous vraiment faire ce dépot ?</h5>
      <button
        class="btn bg-warning border-black mx-1 fw-bold"
        @click.prevent="approuveTransactionDepot"
      >
        J'approuve
      </button>
      <button
        class="btn bg-dark mx-2 text-light fw-bold"
        @click.prevent="closeWindowAccept"
      >
        Annuler
      </button>
    </form>
  </div>
  <div class="interfacePaiement" v-show="showInterfacePaiement">
    <form @submit.prevent="interfaceForAcceptDepot">
      <h1 class="text-center p-3">Dépot</h1>
      <div class="form-body">
        <div class="card-body">
          <div class="row pt-3">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Pièce jointe</label>
                <input
                  type="file"
                  class="form-control"
                  @change.prevent="chooseFile"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form-actions">
          <div class="card-body">
            <button
              class="btn bg-warning border-black mx-3 btn-envoyer fw-bold"
              type="submit"
            >
              Envoyer
            </button>
            <button
              type="button"
              class="btn bg-dark mx-2 text-light fw-bold"
              @click="closeWindow"
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="page-wrapper">
    <div class="container-fluid position-relative">
      <div
        class="msg_Toogle_Transaction"
        v-show="togglePopupIfApprouveTransaction"
      >
        <form>
          <h5 class="text-center">Voulez-vous approuver ce dépot ?</h5>
          <button
            class="btn bg-warning border-black mx-1 fw-bold"
            @click.prevent="approuveTransaction"
          >
            J'approuve
          </button>
          <button
            class="btn bg-dark mx-2 text-light fw-bold"
            @click.prevent="closeWindowConfirmation"
          >
            Annuler
          </button>
        </form>
      </div>
      <div class="row page-titles">
        <div class="col-md-5 align-self-center"></div>
        <div class="col-md-7 align-self-center text-end">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb justify-content-end">
              <li class="fw-bold h3">
                <span>Liste des transactions (en attente)</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <section class="spinnerWindow" v-show="spinner">
        <div class="spinner">
          <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </section>
      <div class="icon">
        <a href="javascript:void(0)" class="back h4" @click="$router.go(-1)"
          ><box-icon name="left-arrow-alt" animation="tada"></box-icon>Retour</a
        >
      </div>
    </div>

    <div class="row container">
      <div class="col-md-12">
        <div class="table-responsive">
          <table id="MyTableData" class="table">
            <thead>
              <tr>
                <th class="bg-light">Date de transaction</th>
                <th class="bg-light">Type de l'opération</th>
                <th class="bg-light">Montant (Fcfa)</th>
                <th class="bg-light">Bordereau</th>
                <th class="bg-light">Statut</th>
                <th class="bg-light">Initiateur</th>
                <th class="bg-light">Solde de l'initiateur (Fcfa)</th>
                <th class="bg-light">Numéro</th>
                <th class="bg-light">Approuvée</th>
              </tr>
            </thead>
            <tbody v-if="listVersement.length">
              <tr v-for="(item, index) in listVersement" :key="index">
                <td>
                  {{ new Date(item.created_at).toLocaleDateString("fr") }}
                </td>
                <td>{{ item.type }}</td>
                <td>{{ moneyFormat.format(item.amount) }}</td>
                <td>
                  <span v-if="item.proof">
                    <n-image
                      :src="
                        'https://backend.invest-ci.com/public/' + item.proof
                      "
                      width="50"
                      :alt="item.proof"
                    />
                  </span>
                  <span v-else class="badge bg-danger">x</span>
                </td>
                <td>
                  <span
                    class="badge bg-dark"
                    v-if="item.state == 'en attente'"
                    >{{ item.state }}</span
                  >
                  <span class="badge bg-success" v-if="item.state == 'validé'"
                    >Approuvé</span
                  >
                </td>
                <td>
                  {{ `${item.initiateur.nom} ${item.initiateur.prenoms}` }}
                </td>
                <td>{{ `${moneyFormat.format(item.initiateur.solde)}` }}</td>
                <td>
                  <span>{{
                    item.initiateur.phone ? item.initiateur.phone : "000000"
                  }}</span>
                </td>
                <td>
                  <button
                    class="btn bg-warning border-black fw-bold"
                    v-if="item.state == 'en attente' && item.type == 'depot'"
                    @click.prevent="
                      showMessageForApprobationTransaction(item.id)
                    "
                  >
                    Approuvée
                  </button>
                  <button
                    class="btn bg-warning border-black fw-bold"
                    v-if="item.state == 'en attente' && item.type == 'retrait'"
                    @click.prevent="showInterface(item.id)"
                  >
                    Approuvée
                  </button>
                  <span v-if="item.state == 'validé'" class="text-warning"
                    ><em class="bi bi-dash-circle"></em
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { lien } from "/src/assets/api.js";
import $ from "jquery";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
export default {
  name: "seeVersement",
  data() {
    return {
      listVersement: [],
      isLoading: false,
      showInterfaceForAccptDepot: false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      user: null,
      solde: null,
      spinner: false,
      IdTransaction: "",
      togglePopupIfApprouveTransaction: false,
      showInterfacePaiement: false,
      bordereau: {},
      transactionActuel: {
        amount: "",
      },
      amount: "",
      typeOperation: "depot",
      state: "en attente",
    };
  },
  components: {
    Loading,
  },
  methods: {
    interfaceForAcceptDepot() {
      this.showInterfaceForAccptDepot = !this.showInterfaceForAccptDepot;
    },
    closeWindowAccept() {
      this.showInterfaceForAccptDepot = !this.showInterfaceForAccptDepot;
    },
    chooseFile(file) {
      this.bordereau = file.target.files[0];
      //console.log("FiCHEIR",this.bordereau)
    },
    showInterface(idVersement) {
      this.IdTransaction = idVersement;
      for (let versement of this.listVersement) {
        if (versement.id == idVersement) {
          this.transactionActuel = versement;
        }
      }
      //console.log("this.transactionActuel ",this.transactionActuel )
      this.showInterfacePaiement = !this.showInterfacePaiement;
    },
    closeWindow() {
      this.IdTransaction = "";
      this.bordereau = {};
      this.showInterfacePaiement = !this.showInterfacePaiement;
    },
    getTransactions() {
      axios.get(lien + "/api/operations").then((res) => {
        //console.log("LISTEVERSEMENT", res);
        this.listVersement = res.data.data;
        this.$store.state.alarmNumberTransaction = res.data.data.length;
        // console.log("LISTEVERSEMENT", this.listVersement);
        // console.log("NUMBERALARM", this.$store.state.alarmNumberTransaction);

        this.isLoading = false;
        setTimeout(function () {
          $("#MyTableData").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,
            order: [],
            language: {
              décimal: "",
              emptyTable: "Aucune donnée disponible dans le tableau",
              infoEmpty: "Showing 0 to 0 of 0 entries",
              info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
              infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
              infoPostFix: "",
              thousands: ",",
              lengthMenu: "Afficher les entrées du _MENU_",
              loadingRecords: "Loading...",
              processing: "Processing...",
              search: "Chercher :",
              stateSave: true,
              zeroRecords: "Aucun enregistrement correspondant trouvé",
              paginate: {
                first: "Premier",
                last: "Dernier",
                next: "Suivant",
                previous: "Précédent",
              },
              aria: {
                sortAscending: ": activate to sort column ascending",
                sortDescending: ": activate to sort column descending",
              },
            },
          });
        }, 10);
      });
    },
    closeWindowConfirmation() {
      this.IdTransaction = "";
      this.togglePopupIfApprouveTransaction =
        !this.togglePopupIfApprouveTransaction;
    },
    showMessageForApprobationTransaction(idVersement) {
      this.IdTransaction = idVersement;
      //console.log("this.IdTransaction",this.IdTransaction)
      this.togglePopupIfApprouveTransaction =
        !this.togglePopupIfApprouveTransaction;
    },
    approuveTransactionDepot() {
      this.spinner = true;
      let dataSend = new FormData();
      dataSend.append("proof", this.bordereau);
      dataSend.append("state", "validé");

      axios
        .post(lien + "/api/operation-update/" + this.IdTransaction, dataSend)
        .then((res) => {
          //console.log(res)
          if (res.data.status === true) {
            Swal.fire({
              text: "Envoi réussi",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.listVersement.forEach((item) => {
              if (item.id === this.IdTransaction) {
                item.state = "validé";
              }
            });
            this.showInterfaceForAccptDepot = !this.showInterfaceForAccptDepot;
            this.showInterfacePaiement = !this.showInterfacePaiement;
            this.spinner = false;
            setTimeout(() => {
              const ObjetDelete = this.listVersement.findIndex(
                (trans) => trans.id == this.IdTransaction
              );
              this.listVersement.splice(ObjetDelete, 0);
            }, 1000);
          }
          console.log("APPROUVE", res);
          if (res.data.status === false) {
            Swal.fire({
              text: "Veuillez contacter votre service informatique pour plus de détails.",
              icon: "error",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.spinner = false;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            text: "Veuillez contacter votre service informatique pour plus de détails.",
            icon: "info",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    approuveTransaction() {
      this.spinner = true;
      let dataSend = {
        state: "validé",
      };
      axios
        .post(lien + "/api/operation-update/" + this.IdTransaction, dataSend)
        .then((res) => {
          console.log(res);
          if (res.data.status === true) {
            this.$store.commit("CHANGE_ALARM_TRANSACTIONS");
            this.listVersement.forEach((item) => {
              if (item.id === this.IdTransaction) {
                item.state = "validé";
              }
            });
            this.togglePopupIfApprouveTransaction =
              !this.togglePopupIfApprouveTransaction;

            const ObjetDelete = this.listVersement.findIndex(
              (trans) => trans.id == this.IdTransaction
            );
            this.listVersement.splice(ObjetDelete, 0);

            this.spinner = false;
          }
          console.log("APPROUVE1", res);
          if (res.data.status === false) {
            Swal.fire({
              text: "Une erreur s'est produite,veuillez contacter votre service informatique pour plus de détails.",
              icon: "error",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.spinner = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.spinner = false;
        });
    },
    PosterbordereauPaiement() {
      this.spinner = true;
      let dataSend = new FormData();
      dataSend.append("proof", this.bordereau);
      dataSend.append("amount", this.transactionActuel.amount);
      dataSend.append("state", this.state);
      dataSend.append("type", this.typeOperation);

      //console.log("amount", this.amount)
      //console.log("state", this.state)
      //console.log("type", this.typeOperation)
      //console.log("bordereau", this.bordereau)
      //console.log("DATA BEFORE SEND",dataSend)

      axios
        .post(lien + "/api/operations", dataSend)
        .then((reponse) => {
          //console.log("VOIR TRANSACTION MSG", reponse);
          if (reponse.data.status === true) {
            Swal.fire({
              text: "Fichier Télecharger avec sucèss",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
            setTimeout(() => {
              this.amount = "";
              this.state = "";
              this.typeOperation = "";
              this.showInterfaceForAccptDepot =
                !this.showInterfaceForAccptDepot;
              this.showInterfacePaiement = !this.showInterfacePaiement;
            }, 1500);
            this.spinner = false;
          }
          if (reponse.data.status === "false") {
            Swal.fire({
              text: reponse.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
            this.spinner = false;
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            text: "Contactez votre service informatique.",
            icon: "info",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
          });
          this.spinner = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.getTransactions();
  },
};
</script>
<style scoped>
.spinner {
  background-color: rgb(27, 27, 27) !important;
  width: 45px;
  height: 45px;
  padding: 0.5em 0 0 0;
  border-radius: 5px;
}
.spinnerWindow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(103, 101, 101, 0.295);
  z-index: 9999;
  display: flex;
  place-items: center;
  justify-content: center;
}
.acceptDepot {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.acceptDepot form {
  width: auto;
  padding: 1em;
  background-color: rgb(246, 246, 246);
  border-radius: 10px;
  border: 5px solid rgb(233, 199, 4);
}
.interfacePaiement {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 80;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
form {
  width: 50%;
  background-color: rgb(246, 246, 246);
  border-radius: 10px;
  border: 5px solid rgb(233, 199, 4);
}
.check {
  cursor: pointer;
}
.my_footer {
  position: relative !important;
  bottom: -39em;
}
.icon {
  position: absolute;
  left: 1em;
  top: 0;
}
.table {
  border: thin solid rgba(139, 139, 139, 0.63) !important;
}
th,
td {
  border: thin solid rgba(141, 140, 140, 0.692) !important;
}
.user {
  text-align: left;
}
input,
select {
  border: 1px solid black !important;
}
.form-group {
  text-align: left !important;
}
label {
  font-weight: bold !important;
}
.msg_Toogle_Transaction {
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  display: flex;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.303);
}
.msg_Toogle_Transaction form {
  width: auto;
  border-radius: 5px;
  padding: 2em;
  background-color: white;
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
